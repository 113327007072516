<template>
    <div>
        <div v-if="media">
            <div class="flex">
                <div class="w-2/3">
                    <img
                        v-if="media.base64"
                        :src="media.base64"
                    >
                    <div v-else>
                        <span class="text-red-500">Could not return the image bas64 from the api.</span>
                    </div>
                </div>
                <div class="ml-6 w-1/3">
                    <div class="mb-8">
                        <p class="text-gray-500 text-xs mb-1">
                            file name
                        </p>
                        <p class="text-black break-words">
                            {{ media.original_name }}
                        </p>
                    </div>
                    <div class="mb-6">
                        <p class="text-gray-500 text-xs mb-1">
                            file size
                        </p>
                        <p class="text-black">
                            {{ media.file_size }}
                        </p>
                    </div>
                    <div class="mb-6">
                        <p class="text-gray-500 text-xs mb-1">
                            based directory
                        </p>
                        <p class="text-black">
                            {{ media.base_directory }}
                        </p>
                    </div>
                    <div class="mb-6">
                        <p class="text-gray-500 text-xs mb-1">
                            uploaded at
                        </p>
                        <p class="text-black">
                            {{ createdAt }}
                            <span
                                class="text-xs text-blue-500 cursor-pointer ml-2 select-none"
                                @click="toggleTimeFormat"
                            >
                                change format
                            </span>
                        </p>
                    </div>
                    <div class="mb-6">
                        <p class="text-gray-500 text-xs mb-1">
                            tags
                        </p>
                        <div class="flex justify-start items-center">
                            <div class="w-2/3">
                                <tags-input v-model="tagsInput" />
                            </div>
                            <span
                                class="text-sm text-black font-medium ml-1 cursor-pointer"
                                @click="saveTags"
                            >save</span>
                        </div>
                        <span class="form-hint">add tags by hitting enter and click save</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Auth from '@/mixins/Auth';
import { mapActions } from 'vuex';
import EventBus from '@/utils/EventBus';
import NotifyMixin from '@/mixins/NotifyMixin';
import TagsInput from '@/components/ui/TagsInput';
import ModalNavigation from '@/mixins/ModalNavigation';

export default {
    components: { TagsInput },
    mixins: [ModalNavigation, Auth, NotifyMixin],
    data: () => {
        return {
            media: null,
            tagsInput: [],
            timeFormat: '24',
        };
    },

    computed: {
        createdAt() {
            let format = this.timeFormat === '24' ? 'Y-M-D HH:mm' : 'Y-M-D  hh:mm A';
            return moment(this.media.created_at).format(format);
        },
    },

    created() {
        this.$filemanagerDataProvider
            .get('fileInfo', { fileId: this.$route.params.id })
            .then(response => {
                this.media = response;
                // load file tags
                if (response.tags && response.tags.length) {
                    response.tags.forEach(tag => {
                        this.tagsInput.push({ key: tag.tag_uuid, value: tag.tag_string });
                    });
                }
            })
            .catch(() => {
                this.notifyError('Error while fetching media.');
            });
    },

    mounted() {
        EventBus.on('confirm-delete-media', () => {
            this.delete();
        });
    },

    methods: {
        ...mapActions({
            removeFileFromLibrary: 'files/removeFileFromLibrary',
        }),

        toggleTimeFormat() {
            this.timeFormat = this.timeFormat === '24' ? '12' : '24';
        },

        delete() {
            this.$filemanagerDataProvider
                .delete('deleteFile', { id: this.media.file_uuid })
                .then(() => {
                    this.removeFileFromLibrary({ file: this.media });
                    this.redirect('medias.index');
                    this.media = null;
                    this.notifySuccess('File was deleted successfully.');
                })
                .catch(() => {
                    this.notifyError('Error while deleting file.');
                });
        },

        saveTags() {
            this.removeFileTagsIfApplicable();
            this.createFileTagsIfApplicable();
            if (this.tagsInput.length) this.notifySuccess('Tags updated.');
            else this.notifySuccess('Tags removed');
        },

        createFileTagsIfApplicable() {
            let tags = [];

            this.tagsInput.forEach(tag => {
                tags.push(tag.value);
            });

            if (tags.length) {
                let data = {
                    file_uuid: this.media.file_uuid,
                    tag: tags.join(','),
                };

                this.$filemanagerDataProvider.create('fileTags', { data: data }).catch(() => {
                    this.notifyError('Error while saving tag.');
                });
            }
        },

        removeFileTagsIfApplicable() {
            let inputTags = this.tagsInput.map(tag => tag.value);
            let tagsToRemove = this.media.tags.filter(tag => {
                if (!inputTags.includes(tag.tag_string)) return tag;
            });

            if (tagsToRemove.length) {
                tagsToRemove.forEach(tag => {
                    this.$filemanagerDataProvider.delete('deleteTag', { id: tag.tag_uuid }).catch(() => {
                        this.notifyError('Error while removing tag.');
                    });
                });
            }
        },
    },
};
</script>
